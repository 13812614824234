<template>
  <div :class="['theme', {'theme--ar-show' : ar},
													{'theme--dl-show' : dl},
													{'theme--ds-show' : ds},
													{'theme--ed-show' : ed},
													{'theme--hv-show' : hv},
													{'theme--sd-show' : sd},
													{'theme--wm-show' : wm}]" stage="theme-ds">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		ar: Boolean,
		dl: Boolean,
		ds: Boolean,
		ed: Boolean,
		hv: Boolean,
		sd: Boolean,
		wm: Boolean,
	},
}
</script>

<style lang="scss" scoped>
.theme{
	width: 100%;
	display: none;
}
.theme[stage="theme-ar"]{
	&.theme--ar-show{
		display:block;
	}
}
.theme[stage="theme-dl"]{
	&.theme--dl-show{
		display:block;
	}
}
.theme[stage="theme-ds"]{
	&.theme--ds-show{
		display:block;
	}
}
.theme[stage="theme-ed"]{
	&.theme--ed-show{
		display:block;
	}
}
.theme[stage="theme-hv"]{
	&.theme--hv-show{
		display:block;
	}
}
.theme[stage="theme-sd"]{
	&.theme--sd-show{
		display:block;
	}
}
.theme[stage="theme-wm"]{
	&.theme--wm-show{
		display:block;
	}
}
</style>

<!-- Used in : DS -->
